import React from 'react'
import PropTypes from 'prop-types'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Img from 'gatsby-image'
import styled from '@emotion/styled'

import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import createOffer from '../utils/createOffer'

import addCTA from '../utils/addCTA'
import addCTAListeners from '../utils/addCTAListeners'
import setGeoTags from '../utils/geoFetch'

import {
  Banner,
  BlogBody,
  BlogContent,
  BlogHeader,
  BlogTitle,
  BlogWrapper,
  Meta,
  MetaAuthor,
  MiniPost,
  offerButton,
  StyledArticlesSection,
  StyledImage,
} from '../styles'


export class BlogPostTemplate extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isMounting: true,
    }
  }
  componentDidMount() {
    this.setState({
      isMounting: false,
    })
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if(prevState.isMounting) {
      const { zoneId, campaign, defaultZoneId, defaultCampaignHost } = this.props
      const zoneInfo = zoneId || defaultZoneId || ''
      zoneInfo && campaign && createOffer('','cta', {zoneId: zoneInfo, tag: campaign, domain: defaultCampaignHost})
      const tags = [...this.props.tags]
      tags.push('converted-users')
      setGeoTags()
      const offerButtons = Array.from(
        document.getElementsByClassName('offerButton')
      )
      const multiOfferButtons = Array.from(
        document.getElementsByClassName('multi-ad__btn')
      )
      offerButtons.concat(multiOfferButtons).forEach((el) => {
        addCTAListeners(el, tags)
      })
    }
  }

  render() {
    const {
      content,
      contentComponent,
      description,
      author,
      adButtonText,
      adButtonUrl,
      date,
      title,
      helmet,
      banner,
      authorPicture,
      pathname,
      zoneId,
      defaultZoneId,
      campaign,
      defaultCampaignHost
    } = this.props
    const PostContent = contentComponent || Content
    return (
    <BlogWrapper>
      {helmet || ''}
      <BlogHeader>
        <BlogTitle>
          <Link to={pathname}>
            <h2>{title}</h2>
          </Link>
        </BlogTitle>
        <Meta>
          <time>{date}</time>
          <div>
            <MetaAuthor href="#">
              {author && authorPicture ? (
                <Img fixed={authorPicture.childImageSharp.fixed} />
              ) : null}
              <span>{author}</span>
            </MetaAuthor>
          </div>
        </Meta>
      </BlogHeader>
      <BlogBody>
        <BlogContent>
          <p>{description}</p>
          <PostContent
            css={offerButton}
            content={
              !this.state.isMounting
              && addCTA(
                '<strong>OfferButton</strong>',
                adButtonUrl,
                adButtonText,
                (zoneId || defaultZoneId) && campaign
              )}
          />
          <Banner>
            {banner ? <Img fluid={banner.childImageSharp.fluid} /> : null}
          </Banner>
          <PostContent
            css={offerButton}
            content={
              !this.state.isMounting
              && addCTA(content, adButtonUrl, adButtonText, (zoneId || defaultZoneId) && campaign)
            }
          />
        </BlogContent>
      </BlogBody>
    </BlogWrapper>
  )}
}

BlogPostTemplate.propTypes = {
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
}

const RelatedArticlesContainer = styled(StyledArticlesSection)`
  grid-area: related;
`
const BlogPost = props => {
  const { markdownRemark: post } = props.data

  const {
    description,
    date,
    author,
    adButtonText,
    adButtonUrl,
    banner,
    tags,
    title,
    authorPicture,
    zoneId,
    campaign,
    defaultCampaignHost
  } = post.frontmatter

  const defaultZoneId = props.data.zoneIdInfo.frontmatter.ADVERSE_DEFAULT_ZONE_ID

  const renderRelated = props => {
    if (!props.data.allMarkdownRemark) return

    const { data } = props
    const { edges: posts } = data.allMarkdownRemark
    return (
      <React.Fragment>
        <h2>Related Article</h2>
        <RelatedArticlesContainer>
          {posts.map(({ node: post }) => {
            const {
              frontmatter: { title, date, authorPicture, banner },
              fields: { slug },
            } = post
            return (
              <MiniPost key={slug}>
                <Link to={slug}>
                  {banner && (
                    <StyledImage fluid={banner.childImageSharp.fluid} />
                  )}
                </Link>
                <header>
                  <div>
                    <h3>
                      <Link to={slug}>{title}</Link>
                    </h3>
                    <time>{date}</time>
                  </div>
                  <div>
                    <Link to={slug}>
                      <Img fixed={authorPicture.childImageSharp.fixed} />
                    </Link>
                  </div>
                </header>
              </MiniPost>
            )
          })}
        </RelatedArticlesContainer>
      </React.Fragment>
    )
  }

  return (
    <Layout IsBlogPage={!!props.data.allMarkdownRemark}>
      <BlogPostTemplate
        content={post.html}
        contentComponent={HTMLContent}
        description={description}
        date={date}
        author={author}
        adButtonText={adButtonText}
        adButtonUrl={adButtonUrl}
        banner={banner}
        authorPicture={authorPicture}
        pathname={props.location.pathname}
        zoneId={zoneId}
        defaultZoneId={defaultZoneId}
        campaign={campaign}
        defaultCampaignHost={defaultCampaignHost}
        helmet={
          <Helmet titleTemplate="WealthStick.com - %s">
            <title>{`${title}`}</title>
            <meta name="description" content={`${description}`} />
            <script data-ad-client="ca-pub-9132700201521447" async src="https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
          </Helmet>
        }
        tags={tags}
        title={post.frontmatter.title}
      />
      {renderRelated(props)}
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!, $country: String, $tags: [String]) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        author
        date(formatString: "MMMM DD, YYYY")
        tags
        title
        description
        country
        adButtonText
        adButtonUrl
        zoneId
        campaign
        defaultCampaignHost
        banner {
          childImageSharp {
            fluid(maxWidth: 1000) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        authorPicture {
          childImageSharp {
            fixed(width: 39, height: 39) {
              ...GatsbyImageSharpFixed
            }
          }
        }
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: { tags: { in: $tags }, country: { eq: $country } }
        id: { ne: $id }
      }
    ) {
      edges {
        node {
          fields {
            slug
          }
          frontmatter {
            title
            date(formatString: "MMMM DD, YYYY")
            banner {
              childImageSharp {
                fluid(maxWidth: 1000) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            authorPicture {
              childImageSharp {
                fixed(width: 39, height: 39) {
                  ...GatsbyImageSharpFixed
                }
              }
            }
          }
        }
      }
    }
    zoneIdInfo: markdownRemark(frontmatter: {ADVERSE_TOKEN: {ne: null}}) {
        frontmatter{
            ADVERSE_DEFAULT_ZONE_ID
        }
    }
  }
`
