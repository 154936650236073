function postDevice(subscription, apiKey, options) {
  (function() {
    subscription = JSON.parse(JSON.stringify(subscription))
    var obj_json = JSON.stringify(options)
    console.log(obj_json)
    var xhr = new XMLHttpRequest()
    xhr.open('POST', 'https://api.upush.co/index.php?do=newDevice')
    xhr.setRequestHeader('Content-Type', 'application/x-www-form-urlencoded')
    xhr.send('upush_data=' + obj_json + '&endpoint=' + subscription.endpoint + '&pkey=' + subscription.keys.p256dh + '&akey=' + subscription.keys.auth + '&api_key=' + apiKey)
  })()
}

function initialiseState(apiKey, options) {
  if (!('showNotification' in ServiceWorkerRegistration.prototype)) {
    console.warn('Notifications aren\'t supported.')
    return
  }
  if (Notification.permission === 'denied') {
    console.warn('The user has blocked notifications.')
    return
  }
  if (!('PushManager' in window)) {
    console.log('Push messaging isn\'t supported.')
    return
  }
  navigator.serviceWorker.ready.then(function(serviceWorkerRegistration) {
    serviceWorkerRegistration.pushManager.getSubscription().then(function(subscription) {
      if (!subscription) {
        return
      }
    }).catch(function(err) {
      console.log('uPush: Error during getSubscription()', err)
    })
  }).catch(function(err) {
    console.log('uPush: Error during initialiseState()', err)
  })
}

function urlBase64ToUint8Array(base64String) {
  const padding = '='.repeat((4 - base64String.length % 4) % 4)
  const base64 = (base64String + padding).replace(/\-/g, '+').replace(/_/g, '/')
  const rawData = window.atob(base64)
  const outputArray = new Uint8Array(rawData.length)
  for (var i = 0; i < rawData.length; ++i) {
    outputArray[i] = rawData.charCodeAt(i)
  }
  return outputArray
}

var checkRemotePermission = function(permissionData, apiKey, options) {
  if (permissionData.permission === 'default') {
    window.safari.pushNotification.requestPermission('https://sdk.upush.co', 'web.co.upush.123', {}, checkRemotePermission)
  } else if (permissionData.permission === 'granted') {
    var subscription = new Object()
    subscription.endpoint = permissionData.deviceToken
    subscription.keys = new Object()
    subscription.keys.p256dh = ''
    subscription.keys.auth = ''
    postDevice(subscription, apiKey, options)
  }
}

function upushPermission(apiKey, options) {
  if ('safari' in window && 'pushNotification' in window.safari) {
    var permissionData = window.safari.pushNotification.permission('web.co.upush')
    checkRemotePermission(permissionData, apiKey, options)
  } else {
    if ('serviceWorker' in navigator) {
      navigator.serviceWorker.register('/uPushSW.js').then(initialiseState(apiKey, options))
    } else {
      console.log('uPush: Service workers aren\'t supported in this browser.')
    }
    navigator.serviceWorker.ready.then(function(serviceWorkerRegistration) {
      serviceWorkerRegistration.pushManager.subscribe({
        userVisibleOnly: true,
        applicationServerKey: urlBase64ToUint8Array('BI-UEhKUwfCQO2lUKKdQuLXEcc2hIywzVM8eOnzWICjz8LyCM5KNpPO4_Lz9CzI3nz2RYqhnNay3HMbwQZk1CvY')
      }).then(function(subscription) {
        return postDevice(subscription, apiKey, options)
      }, function(err) {
        console.log('uPush: Cannot subscribe()')
        console.log(err)
      }).catch(function(e) {
        if (Notification.permission === 'denied') {
          console.log('uPush: Permission for Notifications was denied')
        } else {
          console.log('uPush: Unable to subscribe to push.', e)
        }
      })
    }).catch(function(e) {
      console.log('uPush: Subscription error: ', e)
    })
  }
}

function upushRequest(apiKey, options) {
  if (typeof options == 'undefined' || typeof options.template == 'undefined') {
    upushPermission(apiKey, options)
  }
}

export default upushRequest
