import { replace } from 'lodash'
import qs from 'qs'

const addCTA = (content, ctaurl, text, scriptInfo) => {
  const params = qs.parse(window.location.search.slice(1))
    const hrefShow = 'https://www.showm3.com/product.php?' + qs.stringify({
      site: params.site,
      click: 1,
      pubid: window.location.href,
    })
  if(scriptInfo && !params.ctaurl && !(params.cta || params.cta1)) {
    return replaceFlags(content, '<div class="offersTarget"></div>')
  }
  if(params.ctaurl) ctaurl = params.ctaurl
  if(params.cta1) {
    const buttonField = renderMultiButtonField(params, text, hrefShow)
    return replaceFlags(content, buttonField)
  } else {
    if(params.cta) text = params.cta
    const button = renderButton(ctaurl, text, hrefShow)
    return replaceFlags(content, button)
  }
}

const replaceFlags = (replaced, item) => {
  replaced = replace(
    replaced,
    /<strong>OfferButton<\/strong>/gi,
    item ? item : ''
  )
  const h4Ind = replaced.lastIndexOf('<h4>')
  const h5Ind = replaced.lastIndexOf('<h5>')
  if(h4Ind !== -1 && item) {
    replaced = replaced.substr(0, h4Ind)
      + item + replaced.substr(h4Ind)
  } else if(h5Ind !== -1 && item) {
    replaced = replaced.substr(0, h5Ind)
      + item + replaced.substr(h5Ind)
  }
  return replaced
}

const renderButton = (ctaurl, text, hrefShow) => {
  if(!text || (!ctaurl && !hrefShow)) return
  if(ctaurl.split('ref')[1]) {
    ctaurl = encodeUrl(ctaurl)
    const ctaurlOptions = qs.parse(ctaurl)
    if (ctaurlOptions.ref !== undefined) {
      ctaurlOptions.ref = encodeURIComponent(
        window.location.href.split('?')[0]
      )
    }
    ctaurl = decodeURIComponent(qs.stringify(ctaurlOptions))
    ctaurl = encodeUrl(ctaurl)
  }

  if (ctaurl.includes('showm3')) {
    ctaurl = ctaurl.concat('&') + qs.stringify({
      pubid: window.location.href
    })
  }

  const url = ctaurl || hrefShow
  return (
    ` <a 
        class="offerButton"  
        role="adverse" 
        href=${replace(url, / /gi, '%20')}
        target="_blank"
      >
        ${text}
      </a> 
    `
  )
}

const renderMultiButtonField = (params, text, hrefShow) => {
  const paramKeys = Object.keys(params)
  const ctaRegex = /cta[\d]/

  const ctaTexts = paramKeys.reduce((acc, curKey) => {
    if (!ctaRegex.test(curKey)) return acc
    return acc.concat(params[curKey])
  }, [])

  const buttons = ctaTexts.map((text) => {
    return renderMultiButton(hrefShow, text, params.ctaurl)
  })

  return(`
    <div class="multi-offer">
        <div class="disclaimer">Related Topics (Ads):</div>
        <ul class="multi-offer__list">
            ${buttons.join('')}
        </ul>
    </div>
  `)
}

const renderMultiButton = (hrefShow, text, ctaurl) => {
  if(!text || (!ctaurl && !hrefShow)) return
  ctaurl = decodeURIComponent(ctaurl)
  if (ctaurl.split('ref')[1]) {
    ctaurl = encodeUrl(ctaurl)
    const ctaurlOptions = qs.parse(ctaurl)
    ctaurlOptions.compkey = text

    if (ctaurlOptions.ref !== undefined) {
      ctaurlOptions.ref = encodeURIComponent(
        window.location.href.split('?')[0]
      )
    }
    ctaurl = decodeURIComponent(qs.stringify(ctaurlOptions))
    ctaurl = encodeUrl(ctaurl)
  } else {
    ctaurl = ctaurl + '/?compkey=' + text
  }

  if (ctaurl.includes('showm3')) {
    ctaurl = ctaurl.concat('&') + qs.stringify({
      pubid: window.location.href
    })
  }
  const url = ctaurl || hrefShow

  return(
    `<li>    
      <a class="multi-offer__btn" href=${replace(url, / /gi, '%20')} target="_blank">
        ${text}
        <span class="chevron"/>  
      </a> 
    </li>`
  )                                                         
}

const encodeUrl = (ctaurl) => {
  if (ctaurl.split('&impression_track_url=')[1]) {
    const urls = ctaurl.split('&impression_track_url=')
    const impressionTrackUrls = urls[1].split('&search_track_url=')
    const searchClickTrackUrls = impressionTrackUrls[1].split('&click_track_url=')
    ctaurl = urls[0] +
      '&impression_track_url=' + encodeURIComponent(impressionTrackUrls[0]) +
      '&search_track_url=' + encodeURIComponent(searchClickTrackUrls[0]) +
      '&click_track_url=' + encodeURIComponent(searchClickTrackUrls[1])
  }
  return ctaurl
}

export default addCTA