import { adverseUrl } from './constants'
import addCTAListeners from './addCTAListeners'

const createOffer = (queryParams, type, scriptInfo) => {
  const qsa = document.querySelectorAll.bind(document)
  const script = document.createElement('script')
  const isMobile = () => {
    return window.innerWidth < 500
  }

  document.querySelectorAll = (sel) => {
    if (sel !== 'script') return qsa(sel)
    document.querySelectorAll = qsa
    return [script]
  }

  if(type === 'cta'){
    fillScriptData(queryParams, type, script, isMobile, scriptInfo)
    document.querySelector('body').appendChild(script)
  } else {
    window.offersTarget.appendChild(
      fillScriptData(queryParams, type, script, isMobile)
    )
  }
}

const fillScriptData = (queryParams, type, script, isMobile, scriptInfo) => {
  script.src = adverseUrl
  script.dataset.renderMode = isMobile() ? 'mobile' : 'native'
  script.dataset.adFeedCount = '4'
  script.dataset.decisionTreeId = queryParams.decisionTreeId

  switch (type) {
    case 'mn':
      script.dataset.zoneid = queryParams.zoneId || 'yoo1a0'
      script.dataset.originId = queryParams.originId || '19876'
      script.dataset.creditScore = queryParams.creditScore || 'unknown'
      break
    case 'cta':
      script.dataset.manualRender = true
      script.onload = () => {
        const target = document.querySelectorAll('.offersTarget')
        const config = {childList: true}
        const observer = new MutationObserver((e) => {
          e[0].target.querySelectorAll('a').forEach((node) => {
            addCTAListeners(node, ['converted-users'])
          })
        })
        target.forEach((node) => {
          observer.observe(node, config)
          window.adverse(node, scriptInfo)
        })
      }
    default:
      script.dataset.zoneid = queryParams.zoneId || 'a9xaqu'
      script.dataset.originId = queryParams.originId
      break
  }
  return script
}

export default createOffer